import {
  InstagramOutlined,
  TikTokOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";
import React from "react";
import styled from "styled-components";
import BasicText from "../../components/atomics/Text/BasicText";
import Colors from "../../constants/Colors";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "antd";

const Tail2 = () => {
  const navigate = useNavigate();

  const showTermsofUseModal = () => {
    window.open("https://navitalk.co.kr/termsofuse");
  };

  const showPrivacyPolicyModal = () => {
    window.open("https://navitalk.co.kr/privacypolicy");
  };

  const showInformation = () => {
    // setIsInformation(true)
    navigate("/information");
  };
  const showAddress = () => {
    // setIsAdress(true)
    window.open("https://navitalk.co.kr/adressadmit");
  };

  return (
    <StyledMain>
      <div className="header">
        <div>
          <BasicText
            bold={700}
            size={35}
            color={Colors.primary}
            className="header__title"
          >
            나비맥 (NAVIMAG)
          </BasicText>
        </div>
        <div className="icon__layout">
          <div
            className="icon"
            onClick={() =>
              window.open(
                "https://www.instagram.com/navimag_official?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==",
                "_blank"
              )
            }
          >
            <InstagramOutlined />
          </div>
          <div
            className="icon"
            onClick={() =>
              window.open("https://www.youtube.com/@navimag_official", "_blank")
            }
          >
            <YoutubeOutlined />
          </div>
          <div
            className="icon"
            onClick={() =>
              window.open(
                "https://www.tiktok.com/@navimag_official?_t=ZS-8tqRx5VAlnt&_r=1",
                "_blank"
              )
            }
          >
            <TikTokOutlined />
          </div>
        </div>
      </div>
      <div>
        <BasicText bold={700} size={35} className="header__content">
          나비맥에서,
          <br /> 트렌디한 뷰티 이야기,
          <br /> 지금 바로 만나보세요 !
        </BasicText>
      </div>
      <div>
        <div className="contents__layout">
          <div>
            <div>
              <BasicText
                bold={600}
                color={Colors.black}
                size={18}
                lineHeight={1.3}
              >
                주식회사 엠에이디씨
              </BasicText>
            </div>
            <div>
              <BasicText
                bold={600}
                color={Colors.black}
                size={16}
                lineHeight={1.3}
              >
                {" "}
                대표이사 김홍민
              </BasicText>
            </div>
          </div>
          <div>
            <div>
              <BasicText
                bold={700}
                color={Colors.black}
                size={16}
                lineHeight={1.3}
              >
                사업자 등록 번호 606-88-02385
              </BasicText>
            </div>
            <div>
              <BasicText
                bold={600}
                color={Colors.black}
                size={16}
                lineHeight={1.3}
              >
                이용문의 madcom_@naver.com
              </BasicText>
            </div>
          </div>
          <div>
            <div>
              <BasicText
                bold={600}
                color={Colors.black}
                size={16}
                lineHeight={1.3}
              >
                대표전화번호 02-6925-5866
              </BasicText>
            </div>
            <div>
              <BasicText
                bold={600}
                color={Colors.black}
                size={16}
                lineHeight={1.3}
              >
                주소 서울특별시 금천구 가산디지털2로 169-23, 1101-1호
                (가산모비우스타워)
              </BasicText>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              gap: "10px",
              width: "100%",
            }}
          >
            <Row
              // justify="center"
              align="middle"
              gutter={[10, 10]}
              style={{
                width: "100%",
              }}
            >
              <Col xs={14} sm={12} md={4}>
                <div
                  onClick={showTermsofUseModal}
                  style={{
                    border: "none",
                    background: "none",
                    color: "white",
                    cursor: "pointer",
                  }}
                >
                  <BasicText bold={600} size={15}>
                    이용약관
                  </BasicText>
                </div>
              </Col>
              |
              <Col xs={14} sm={12} md={5}>
                <div
                  onClick={showPrivacyPolicyModal}
                  style={{
                    border: "none",
                    background: "none",
                    color: "white",
                    cursor: "pointer",
                  }}
                >
                  <BasicText bold={600} size={15}>
                    개인정보취급방침
                  </BasicText>
                </div>
              </Col>
              {/* |
              <Col xs={14} sm={12} md={6}>
                <div
                  onClick={showInformation}
                  style={{
                    border: "none",
                    background: "none",
                    color: "white",
                  }}
                >
                  <BasicText bold={600} size={15}>
                    개인민감정보처리방침{" "}
                  </BasicText>
                </div>
              </Col> */}
              |
              <Col xs={14} sm={12} md={5}>
                <div
                  onClick={showAddress}
                  style={{
                    border: "none",
                    background: "none",
                    color: "white",
                    cursor: "pointer",
                  }}
                >
                  <BasicText bold={600} size={15}>
                    위치정보 이용약관
                  </BasicText>
                </div>
              </Col>
            </Row>
          </div>
          <div>
            <BasicText>COPYRIGHT(c) 2023 MADC ALL RIGHTS RESERVED</BasicText>
          </div>
        </div>
      </div>
    </StyledMain>
  );
};

export default Tail2;

const StyledMain = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10em 20em 5em 20em;
  gap: 3.5em;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .icon__layout {
    display: flex;
    gap: 4em;
  }

  .icon {
    font-size: 40px;
    color: black;
    cursor: pointer;
  }

  .contents__layout {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
  }

  @media (max-width: 1200px) {
    padding: 5em 5em 5em 10em;
  }

  @media (max-width: 768px) {
    padding: 5em 5em 5em 5em;

    .icon__layout {
      display: flex;
      gap: 2em;
    }

    // .icon__layout {
    //   display: flex;
    //   gap: 2em;
    // }
    // .icon {
    //   font-size: 40px;
    // }
    // background: red;
  }

  @media (max-width: 480px) {
    padding: 5em 3em 5em 3em;
    gap: 2em;

    .header__title {
      font-size: 22px;
    }

    .icon__layout {
      display: flex;
      gap: 1em;
    }

    .icon {
      font-size: 30px;
    }

    .header__content {
      font-size: 25px;
    }
  }
`;

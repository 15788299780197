import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import styled from "styled-components";
import video1 from "../../assets/img/gif/1.mp4";
import video2 from "../../assets/img/gif/2.mp4";
import video3 from "../../assets/img/gif/3.mp4";
import video4 from "../../assets/img/gif/4.mp4";
import video5 from "../../assets/img/gif/5.mp4";

const SwiperComponent: React.FC = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 480);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 480);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <StyledMain>
      <div className="circle" />
      <StyledSwiper
        modules={[Autoplay, Pagination, Navigation]}
        spaceBetween={100}
        slidesPerView={3} // 한 화면에 보여줄 슬라이드 수
        navigation={!isMobile} // 네비게이션(좌우 화살표)
        // pagination={{ clickable: true }} // 페이지네이션 점 클릭 가능
        // scrollbar={{ draggable: true }} // 스크롤바 드래그 가능
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop={true} // 슬라이더 루프 활성화 (마지막 슬라이드가 끝나면 첫 번째로 돌아감)
        centeredSlides={true} // 슬라이드가 항상 중앙에 오도록 설정
      >
        <SwiperSlide>
          <StyledSlide>
            <video src={video1} autoPlay loop muted playsInline />
          </StyledSlide>
        </SwiperSlide>
        <SwiperSlide>
          <StyledSlide>
            <video src={video2} autoPlay loop muted playsInline />
          </StyledSlide>
        </SwiperSlide>
        <SwiperSlide>
          <StyledSlide>
            <video src={video3} autoPlay loop muted playsInline />
          </StyledSlide>
        </SwiperSlide>
        <SwiperSlide>
          <StyledSlide>
            <video src={video4} autoPlay loop muted playsInline />
          </StyledSlide>
        </SwiperSlide>
        <SwiperSlide>
          <StyledSlide>
            <video src={video5} autoPlay loop muted playsInline />
          </StyledSlide>
        </SwiperSlide>
      </StyledSwiper>
    </StyledMain>
  );
};

export default SwiperComponent;

const StyledMain = styled.div``;

// Swiper에 스타일 추가
const StyledSwiper = styled(Swiper)`
  // height: 100%;

  .swiper-wrapper {
    align-items: center;
  }

  // 가운데 슬라이드를 키우는 스타일
  .swiper-slide-active {
    transform: scale(1.1); // 활성화된 슬라이드를 1.1배 키움
    height: 100vh;
    z-index: 2;

    video {
      width: 35vh;
      border-radius: 40px;
    }
  }

  .swiper-slide:not(.swiper-slide-active) {
    video {
      width: 25vh; // 비활성 슬라이드 이미지 크기를 250px로 설정
      transition: width 0.5s ease, height 0.5s ease;
      // border-radius: 15px;
    }
  }

  .swiper-slide swiper-slide-next {
    z-index: 1;
  }
  .swiper-slide swiper-slide-prev {
    z-index: 1;
  }

  @media (max-width: 480px) {
    .swiper-slide-active {
      video {
        width: 30vh;
      }
    }

    .swiper-slide:not(.swiper-slide-active) {
      video {
        width: 25vh; // 비활성 슬라이드 이미지 크기를 250px로 설정
      }
    }
  }
`;

// 각 슬라이드의 스타일
const StyledSlide = styled.div`
  // background-color: lightgray;
  text-align: center;
  // padding: 40px;
  font-size: 24px;
  color: black;
  width: 100%;
  height: 100%;
  display: flex; // Flexbox 사용하여 정렬
  justify-content: center; // 가로 정렬 가운데
  align-items: center; // 세로 정렬 가운데
  // height: 400px;

  img {
    // height: 100%;
    // width: 100%;
    // width: 200px;

    // width: 32vh;
  }
`;

import styled from "styled-components";
import logo5 from "../../assets/img/icon/icon.navimag.png";
import BasicText from "../../components/atomics/Text/BasicText";
import video1 from "../../assets/img/gif/1.mp4";
import video2 from "../../assets/img/gif/2.mp4";
import video3 from "../../assets/img/gif/3.mp4";
import video4 from "../../assets/img/gif/4.mp4";

const MainPage = () => {
  const VIDEO_BASE_URL = process.env.REACT_APP_S3_VIDEO; // .env에서 가져옴
  console.log(VIDEO_BASE_URL, "<--- vidoe");

  const videoFiles = [
    `${VIDEO_BASE_URL}/mp4_1.mp4`,
    `${VIDEO_BASE_URL}/mp4_2.mp4`,
    `${VIDEO_BASE_URL}/mp4_3.mp4`,
    `${VIDEO_BASE_URL}/mp4_4.mp4`,
  ];

  return (
    <StyledMain>
      <div className="header">
        <div>
          <img src={logo5} width={90} className="logo" />
        </div>
        <div>
          <BasicText size={40} fontType="hans" className="header__title">
            나비맥
          </BasicText>
          <br />
          <BasicText size={30} fontType="hans" className="header__subtitle">
            (NAVIMAG)
          </BasicText>
        </div>
      </div>
      <div className="video1">
        <video src={videoFiles[0]} autoPlay loop muted playsInline />
      </div>
      <div className="video2">
        <video src={videoFiles[1]} autoPlay loop muted playsInline />
      </div>
      <div className="video3">
        <video src={videoFiles[3]} autoPlay loop muted playsInline />
      </div>

      <div className="video4">
        <video src={videoFiles[2]} autoPlay loop muted playsInline />
      </div>
      <div className="textCenter">
        <BasicText bold={700} size={40}>
          나비맥이란?
        </BasicText>
      </div>
    </StyledMain>
  );
};

export default MainPage;

const StyledMain = styled.div`
  height: 100vh;
  display: flex;
  // justify-content: center;
  align-items: flex-start;
  position: relative;
  background: radial-gradient(
    ellipse 80% 50% at center,
    #cce7ff 10%,
    #f0f9ff 80%
  ); /* 중앙으로 모이는 그라데이션 */
  overflow: hidden;

  .header {
    display: flex;
    align-items: center;
    height: 10vh;
    padding: 40px 0 0 40px;
    gap: 0.2em;
  }

  // .video {
  //   position: absolute;
  // }

  /* video2는 가장 하단에 위치하면서 흐릿하게 처리 */
  .video2 {
    z-index: 2; /* background보다 위에 있지만 video1,3,4보다 낮음 */
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    opacity: 0.5; /* 투명도 적용 */
    filter: blur(1.5px); /* 흐림 효과 */
    width: 170px;
    top: 5%;
    right: 25%;
  }

  .video4 {
    z-index: 2; /* background보다 위에 있지만 video1,3,4보다 낮음 */
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    opacity: 0.5; /* 투명도 적용 */
    filter: blur(1.5px); /* 흐림 효과 */
    width: 170px;
    top: 55%;
    left: 25%;
  }

  /* 배경을 video2 뒤에 두고 흐림 효과 적용 */
  .background {
    position: absolute;
    z-index: 1; /* video2보다 아래에 배치 */
    width: 100vw;
    height: 100vh;
    background: radial-gradient(
      circle,
      #ffffff80,
      #f0f9ff
    ); /* 약간 투명한 배경 */
    filter: blur(4px); /* 배경 흐림 효과 */
  }

  /* video1, video3, video4는 최상단에 배치 */
  .video1,
  .video3 {
    z-index: 3; /* 가장 높은 z-index */
    opacity: 0.9; /* 약간 투명하게 */
    width: 170px;
  }

  .video1 {
    position: absolute;
    top: 12%;
    left: 10%;
  }

  .video3 {
    position: absolute;
    bottom: 12%;
    right: 10%;
  }

  .video1 video,
  .video2 video,
  .video3 video,
  .video4 video {
    border-radius: 14px;
    width: 100%;
  }

  .textCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: black;
    z-index: 5; /* 모든 비디오 위에 텍스트 표시 */
    width: 100%;
  }

  @media (max-width: 480px) {
    .video1 video,
    .video3 video {
      display: none;
    }

    .header {
      padding: 40px 0 0 20px;
    }

    .logo {
      width: 65px;
    }

    .header__title {
      font-size: 30px;
    }

    .header__subtitle {
      font-size: 25px;
    }

    .video2 video {
      position: absolute;
      top: 7%;
      left: 50%;
      // max-height: 300px;
      width: 120px;
    }

    .video4 video {
      position: absolute;
      top: 0%;
      max-height: 350px;
      left: -10%;
      // right: 50%;
      width: 120px;
    }
  }
`;

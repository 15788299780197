import React from "react";
import styled from "styled-components";
import aiImg from "../../assets/img/ai/ai_img.png";
import icon1 from "../../assets/img/icon/icon_information.png";
import finger from "../../assets/img/finger2.png";
import phone from "../../assets/img/phone.png";
import BasicText from "../../components/atomics/Text/BasicText";
import Colors from "../../constants/Colors";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "antd";

const FirstPage = () => {
  const navigate = useNavigate();

  return (
    <StyledMain>
      <Row
        gutter={[16, 16]} // 여백 설정
        justify="center"
        align="middle"
        className="Layout"
      >
        <Col xs={24} sm={12} md={12} className="frist__layout">
          <div
            className="card__item1"
            onClick={() => {
              window.open("https://www.youtube.com/@navimag_official");
            }}
            style={{
              backgroundColor: "#D0D0CE",
            }}
          >
            <div>
              <div className="title__layout">
                <div className="emo">
                  <img src={finger} width={100} />
                </div>
                <div className="text">
                  <BasicText
                    color={Colors.text}
                    bold={700}
                    size={50}
                    className="text1__style"
                  >
                    찾기 힘들었던
                    <br />
                    컨텐츠 바로 여기 !
                  </BasicText>
                </div>
              </div>
            </div>
            <div className="img__layout">
              <div>
                <img src={aiImg} alt="card__img" width={300} />
              </div>
            </div>
          </div>
        </Col>

        <Col xs={24} sm={12} md={12} className="second__layout">
          <div
            className="card__item2"
            onClick={() => {
              window.open(
                "https://www.instagram.com/navimag_official?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
              );
            }}
          >
            <div className="img__layout2">
              <div>
                <img
                  src={phone}
                  alt="card__img"
                  className="animated-img"
                  width={300}
                />
              </div>
            </div>
            <div>
              <div className="title__layout2">
                <div className="text">
                  <BasicText
                    color={Colors.gray3}
                    bold={700}
                    size={50}
                    className="text2__style"
                  >
                    재미 있는
                    <br />
                    컨텐츠를 한눈에 !
                  </BasicText>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </StyledMain>
  );
};

export default FirstPage;
const StyledMain = styled.div`
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #005a9e, #6d00b7);
  gap: 25em;

  .frist__layout,
  .second__layout {
    min-width: 500px;
  }

  .frist__layout,
  .second__layout {
    width: 100%;
  }

  .Layout {
    height: 100vh;
  }

  .title__layout {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5em 0em 0em 0em;
    justify-content: space-around;
  }

  .title__layout .emo {
    flex: 1;
  }

  .title__layout .text {
    flex: 1;
    width: 100%;
  }

  .img__layout {
    position: relative; /* 자식 요소의 절대 위치를 설정할 수 있도록 함 */
    background-color: #d0d0ce;
    display: flex;
    justify-content: flex-end;
    width: 100%; /* 부모 요소의 넓이에 맞춰서 설정 */
  }

  .img__layout img {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .card__item1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    height: 600px;
    width: 100%;
    max-width: 500px;
    border-radius: 50px;
    flex-direction: column;
    cursor: pointer;
    position: relative; /* 이미지의 절대 위치 설정을 위한 relative */
  }

  // ----------------------------------------------------------------

  .card__item2 {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: rgba(0, 0, 0, 0.5); /* 어두운 배경 */
    height: 600px;
    width: 100%;
    // width: 500px;
    max-width: 500px;
    border-radius: 50px;
    cursor: pointer;
    flex-direction: column;
    border: 1px solid rgba(255, 255, 255, 0.18); /* 약간의 테두리 */
    color: white; /* 텍스트를 밝게 해서 어두운 배경에서 잘 보이도록 */
  }

  .img__layout2 {
    display: flex;
    // padding: 5em;
    margin-top: 5em;
  }

  .title__layout2 {
    width: 100%;
  }

  .animated-img {
    width: 300px;
    height: auto;
    position: relative;
    border-radius: 20px;
  }

  @media (max-width: 983px) {
    .Layout {
      height: 100%;
      padding: 5em 0 5em 0;
    }
  }

  @media (max-width: 768px) {
    // :where(.css-dev-only-do-not-override-5wsri9).ant-col-xs-24 {
    //   flex: 0 0 auto; /* 기본 크기를 컨텐츠에 맞추도록 변경 */
    //   max-width: none; /* 최대 너비 제한 해제 */
    // }

    .Layout {
      height: 100%;
      padding: 5em 0 5em 0;
    }
  }

  @media (max-width: 480px) {
    .Layout {
      width: 80%;
      // background-color: red;
    }

    .frist__layout,
    .second__layout {
      min-width: 0px;
    }

    .frist__layout,
    .second__layout {
      // width: 100%;
    }

    .card__item1,
    .card__item2 {
      border-radius: 15px;
    }

    .img__layout {
      // width: 50%;
    }

    .title__layout {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 5em 2em 0em 2em;
      // justify-content: space-around;
    }

    .text1__style {
      font-size: 28px;
    }

    .text2__style {
      font-size: 32px;
    }

    .img__layout img {
      // width: 100%;
    }

    .img__layout2 img {
      // width: 100%;
    }
  }

  @media (max-width: 400px) {
    .img__layout img {
      width: 100%;
    }

    .img__layout2 img {
      width: 100%;
    }
  }
`;

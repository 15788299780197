import React from "react";
import FirstPage from "./first/FirstPage";
import SecondPage from "./second/SecondPage";
import ThirdPage from "./third/ThirdPage";
import MainPage from "./main/MainPage";
import Tail from "./tail/Tail";
import Slide from "../components/third/Slide";
import BannerImg from "../components/banner/BannerImg";
import {
  CustomerServiceOutlined,
  InstagramOutlined,
  TikTokOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";
import { FloatButton } from "antd";
import { useNavigate } from "react-router-dom";
import Tail2 from "./tail/Tail2";

const Index = () => {
  const navigate = useNavigate();

  return (
    <>
      <MainPage />
      <FirstPage />
      <SecondPage />
      <BannerImg />

      <Tail2 />
      <FloatButton
        shape="square"
        type="primary"
        style={{ insetInlineEnd: 24 }}
        icon={<YoutubeOutlined />}
        onClick={() =>
          window.open("https://www.youtube.com/@navimag_official", "_blank")
        }
      />

      <FloatButton
        shape="circle"
        type="primary"
        style={{ insetInlineEnd: 94, backgroundColor: "#FF277B" }}
        icon={<InstagramOutlined />}
        onClick={() =>
          window.open(
            "https://www.instagram.com/navimag_official?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==",
            "_blank"
          )
        }
      />
      <FloatButton
        shape="square"
        type="primary"
        style={{ insetInlineEnd: 164 }}
        icon={<TikTokOutlined />}
        onClick={() =>
          window.open(
            "https://www.tiktok.com/@navimag_official?_t=ZS-8tqRx5VAlnt&_r=1",
            "_blank"
          )
        }
      />

      {/* <ThirdPage /> */}
      {/* <Tail /> */}
    </>
  );
};

export default Index;

import React from "react";
import styled from "styled-components";
import Colors from "../../../constants/Colors";

export interface IBasicText {
  fontType?: "pretendard" | "hans"; // 폰트 타입 추가
  bold?: number;
  size?: number;
  lineHeight?: number | string;
  color?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

const BasicText: React.FC<IBasicText> = ({
  fontType = "pretendard", // 기본값 Pretendard
  children,
  bold = 400,
  size = 14,
  lineHeight = "14.4px",
  color = Colors.black,
  className = "",
  ...restProps
}) => {
  return (
    <StyledText
      fontType={fontType}
      bold={bold}
      size={size}
      lineHeight={lineHeight}
      color={color}
      className={className}
      {...restProps}
    >
      {children}
    </StyledText>
  );
};

export default BasicText;

const StyledText = styled.span<IBasicText>`
  font-family: ${({ fontType = "pretendard", bold = 400 }) =>
    getFontFamily(fontType, bold)};
  font-weight: ${({ bold }) => bold};
  font-size: ${({ size }) => size}px;
  line-height: ${({ lineHeight }) => lineHeight}px;
  color: ${({ color }) => color};
`;

const getFontFamily = (fontType: "pretendard" | "hans", bold: number) => {
  if (fontType === "hans") return "Black Han Sans"; // Black Han Sans 적용

  switch (bold) {
    case 400:
      return "Pretendard-Regular";
    case 500:
      return "Pretendard-Medium";
    case 600:
      return "Pretendard-SemiBold";
    case 700:
      return "Pretendard-Bold";
    default:
      return "Pretendard-Regular";
  }
};
